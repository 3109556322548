<template>
    
    <div>

        <v-form @submit.prevent="signupHandlerSubmit" ref="form" class="row signupForm">
            
            <div class="col-lg-6">
                <v-text-field
                    v-model="signupUser.name"
                    :label="$t('signup.namePlaceholder')"
                    :loading="loadingForm"
                    :color="colors.brandPrimColor"
                    light
                    height="40"
                    outlined
                    append-icon="mdi-email-outline"
                    :rules="nameRules"
                    name="userName"
                ></v-text-field>
            </div>
            <div class="col-lg-6">
                <v-text-field
                    v-model="signupUser.mobile"
                    :label="$t('signup.mobilePlaceholder')"
                    :loading="loadingForm"
                    :color="colors.brandPrimColor"
                    light
                    height="40"
                    outlined
                    append-icon="mdi-phone-outline"
                    :rules="mobileRules"
                    
                ></v-text-field>
            </div>
            <div class="col-lg-6">
                <v-text-field
                    v-model="signupUser.email"
                    :label="$t('signup.emailPlaceholder')"
                    :loading="loadingForm"
                    :color="colors.brandPrimColor"
                    light
                    height="40"
                    outlined
                    append-icon="mdi-account-outline"  
                    :rules="emailRules"
                    name="email"

                ></v-text-field>
            </div>
            <div class="col-lg-6">
                <v-text-field
                    v-model="signupUser.password"
                    :append-icon="showPassword ? svg.visibility : svg.visibilityOff"
                    :type="showPassword ? 'text' : 'password'"
                    :label="$t('login.passPlaceholder')"
                    :loading="loadingForm"
                    @click:append="showPassword = !showPassword"
                    :color="colors.brandPrimColor"
                    height="40"
                    outlined
                    autocomplete
                    :rules="passRules"
                    name="password"

                ></v-text-field>
            </div>
            <div class="col-lg-12 treeviewdiv">
                <treeselect
                    :multiple="false"
                    :options="sectorsList"
                    :noChildrenText	="$t('signup.workSectorNoText')"
                    v-model="signupUser.workSector"
                    :placeholder="$t('signup.workSectorPlaceholder')"
                    :value-consists-of="valueConsistsOf"
                    />
                    
            </div>
            
            <!-- <div class="col-lg-12">
                <v-text-field
                    v-model="signupUser.bio"
                    :label="$t('signup.bioPlaceholder')"
                    :loading="loadingForm"
                    :color="colors.brandPrimColor"
                    light
                    height="40"
                    outlined
                    append-icon="mdi-account-badge-horizontal-outline"
                    :rules="bioRules"
                ></v-text-field>
            </div> -->
            <div class="col-lg-12">
                <!-------------------------  FORM ERRORS  ------------------->
                <div v-if="errors" class=" subtitle1 text-center text-capitalize red--text">
                    <!-- <div v-for="(errorMessage, i) in errors" :key="i">
                        <p v-for="(Message, i) in errorMessage" :key="i">{{Message}}</p>
                    </div> -->
                    <p v-for="(errorMessage, i) in errors" :key="i">{{errorMessage}}</p>
                    
                    <!-- <span>Don't have an account? <router-link class="cyan--text" to="/signup">Sign Up Here</router-link></span> -->
                </div>
                <!-------------------------  END FORM ERRORS ------------------->
            </div>
            <div class="col-lg-12">
                <div class="mt-5">
                    <v-btn type="submit" large :loading="loadingForm" :class="{ 'mr-4': locale == 'en' }" class="primBtn mb-2"  :color="colors.brandPrimColor" elevation="0" dark>
                        {{$t('signup.signupBtn')}}
                    </v-btn>
                    <router-link class="v-btn secBtn mr-4 mb-2" to="/login">{{$t('signup.loginBtn')}}</router-link>            
                </div>
            </div>



        </v-form>

        <v-row justify="center">

            <v-dialog
            v-model="dialog"
            persistent 
            max-width="600"
            
            >
            <v-card class="signupSuccessDialog">
                <v-card-title class="headline">{{$t('signup.successDialogH')}}</v-card-title>

                <v-card-text >
                    <i class="fas fa-check-circle large"></i>
                    <p>{{$t('signup.successDialogP')}}</p>
                </v-card-text>
                <v-card-actions>
                    <v-spacer></v-spacer>
                    <router-link :to="{ path: 'login'}">
                       {{$t('signup.successDialoglink')}}
                    </router-link>
                    <!-- <v-btn
                        color="green darken-1"
                        text
                        @click="dialog = false;"
                    >
                        {{$t('signup.successDialoglink')}}
                    </v-btn> -->

                   
                </v-card-actions>   
               
            </v-card>
            </v-dialog>
        </v-row>
    </div>
</template>


<script>
// MIXINS
import { reset } from '@/mixins/mixins';
import i18n from '@/plugins/i18n';
// import the component
  import Treeselect from '@riophae/vue-treeselect'
  // import the styles
  import '@riophae/vue-treeselect/dist/vue-treeselect.css'
  import { ASYNC_SEARCH } from '@riophae/vue-treeselect'
  import { LOAD_CHILDREN_OPTIONS } from '@riophae/vue-treeselect'



// SVG ICONS
import { mdiEyeOutline, mdiEyeOffOutline  } from '@mdi/js';

//VUEX
import { mapGetters } from 'vuex';
import axios from 'axios';


export default {
    mixins: [reset],
     components: { Treeselect },
     data() {
        
        return {
            
            dialog: false,
            valueConsistsOf: 'ALL_WITH_INDETERMINATE',
            showPassword: false,
            signupUser: {
                name:"",
                password:"",
                // bio:"",
                mobile:"",
                email:"",
                workSector: null,
            },
            nameRules:[
                v => !!v || i18n.messages[this.locale].errorsList.signup.errors.reqUserName,
            ],
            // sectorRules:[
            //     v => !!v || i18n.messages[this.locale].errorsList.signup.errors.reqUserName,
            // ],
            // departmentRules:[
            //     v => !!v || i18n.messages[this.locale].errorsList.signup.errors.reqUserName,
            // ],
            // sectionRules:[
            //     v => !!v || i18n.messages[this.locale].errorsList.signup.errors.reqUserName,
            // ],
            emailRules: [
                v => !!v || i18n.messages[this.locale].errorsList.signup.errors.reqEmail,
                v => /.+@.+\..+/.test(v) || i18n.messages[this.locale].errorsList.signup.errors.emailMustValid,
            ],
            
            mobileRules: [
                v => !!v || i18n.messages[this.locale].errorsList.signup.errors.reqMobile,
                v => /^(009665|9665|\+9665|05|5)(5|0|3|6|4|9|1|8|7)([0-9]{7})$/.test(v) || i18n.messages[this.locale].errorsList.signup.errors.mobileMustValid,
            ],
            passRules: [
                v => !!v || i18n.messages[this.locale].errorsList.signup.errors.reqPass,
                v => (v && v.length >= 6) || i18n.t('errorsList.signup.errors.passMoreThanNChars', {'n': '6'}),
            ],
            // bioRules: [
            //     v => !!v || i18n.messages[this.locale].errorsList.signup.errors.reqBio,
            //     v => (v && v.length >= 10) || i18n.t('errorsList.signup.errors.bioMoreThanNChars', {'n': '10'}),
            // ],
            svg: {
                visibility: mdiEyeOutline,
                visibilityOff: mdiEyeOffOutline,
            },
        }
    },
    methods: {
        signupHandlerSubmit() {
            let LocalOrgSlug = process.env.VUE_APP_ORG_SLUG;
            let full = window.location.host;
            let parts = full.split('.');
            let subDomain = parts[0];
            
            if (this.$refs.form.validate()) {
                this.$store.dispatch('SIGN_UP', {
                    "organization": LocalOrgSlug ? LocalOrgSlug : subDomain,
                    "name": this.signupUser.name,
                    "password": this.signupUser.password,
                    // "bio":this.signupUser.bio,
                    "mobile":this.signupUser.mobile,
                    "email":this.signupUser.email,
                    "locale":this.locale,
                    "sector_id": this.signupUser.workSector,

                })
                .then(() => {
                    this.dialog = true;

                })
            }
        },
    },
    computed: {
        ...mapGetters(['colors', 'errors', 'loadingForm', 'theme', 'locale', 'sectorsList'])
    },
    beforeDestroy() {
        this.$store.dispatch('CLEAR_ERROR')
    },
    mounted() {
        this.$store.dispatch('FETCH_SECTORS_LIST');
       
        if(!this.theme.organization.allow_registration){
            this.$router.push({name: 'login'});
        }
    },
}
</script>
<style lang="scss" >
    .col-lg-12 .vue-treeselect .vue-treeselect__control {
        height: 50px !important;
        margin-bottom: 30px;
        border: 1px solid #c2c2c2;
        color: #7b7b7b;
    }
    // fieldset {
    //     display: none;
    // }

    .signupForm {
        .col-lg-6, .col-lg-12 {
            padding: 0 12px !important;
        }
    }

    .vue-treeselect__placeholder {
        line-height: 45px;
    }

    .signupSuccessDialog {
        text-align: center;

        .headline {
            text-align: center;
            margin-bottom: 20px;
            word-break: keep-all;
        }

        i {
            font-size: 70px;
            margin-bottom: 20px;
            color: green;
        }
    }
    .v-application--is-rtl{
        .vue-treeselect {
            text-align: right;
            direction: rtl;
        }
    }

    
</style>