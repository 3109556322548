<template>
    <v-container>
         <v-row justify="center" class="login mt-5 mb-5">
            <!-- <v-col sm="12" md="6" > -->
                <h2>{{$t('signup.welcomeMsg')}},</h2>
                <h4>{{$t('signup.signupTitle')}}!</h4>
                <AppSignUpForm></AppSignUpForm>
                <p class="mt-4">{{$t('signup.verifyMsg')}}</p>

            <!-- </v-col> -->
            <!-- <v-col v-if='windowWidth > 960' sm="0" md="6">
                <div class="login-bg"></div>
            </v-col> -->
        </v-row>
    </v-container>
</template>


<script>
// COMPONENTS
// import AppSignupPanel from '@/components/AppSignupPanel';
import AppSignUpForm from '@/components/Form/AppSignUpForm';

export default {
    components: {
       // AppSignupPanel,
        AppSignUpForm
    },
     data() {
        return {
            windowWidth: window.innerWidth
        }
    },
    mounted() {
        window.onresize = () => {
            this.windowWidth = window.innerWidth
        }
    }
}
</script>
<style lang="scss">
.login {
    flex-direction: column;
    align-items: center;
}
</style>
